import React, { useContext, useState, useEffect, useRef } from "react";
import { Button, Drawer, Input, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../../context/socketContext";
import moment from "moment";
import { IoCheckmark, IoCheckmarkDoneSharp } from "react-icons/io5";
import { MdOutlineAccessTime } from "react-icons/md";
import { ImAttachment } from "react-icons/im";
import { GetAllMessages } from "../../redux/Users/UsersActions";
const { TextArea } = Input;

export default function UserChatDrawer({
  unSeenChat,
  setUnSeenChat,
  setSelectedUser,
  selectedUser,
  open,
  setOpen,
}) {
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const userData = useSelector((state) => state.Auth.userData);
  const usertoken = useSelector((state) => state.Auth.token);
  const { Socket, FiltredUers } = useContext(SocketContext);
  const [allMessages, setallMessages] = useState([]);
  const [myMessages, setmyMessages] = useState("");
  const [incomingMsgs, setincomingMsgs] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [updatedMsg, setUpdatedMsg] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    if (Socket) {
      Socket?.emit(
        "getMyMsgs",
        {
          user1: userData?._id,
          user2: selectedUser?._id,
        },
        (data) => {
          setallMessages(data);
          setLoading(false);
          setTimeout(() => {
            if (messagesEndRef.current) {
              messagesEndRef.current.scrollIntoView({ behavior: "auto" });
            }
          }, 100);
        }
      );
    }
    if (selectedUser !== null) {
      let filterUser = unSeenChat?.filter(
        (x) => x?.userID !== selectedUser?._id
      );
      setUnSeenChat(filterUser);
    }
    if (allMessages?.some((x) => x?.sender !== selectedUser?._id)) {
      setallMessages([]);
    };
    let allDataNew = {
      id: userData?._id
    }
    dispatch(GetAllMessages(allDataNew, usertoken));
  }, [selectedUser]);



  useEffect(() => {
    if (incomingMsgs !== null) {
      if (selectedUser?._id == incomingMsgs?.sender) {
        let updateMsg = { ...incomingMsgs };
        updateMsg.status = "seen";
        Socket?.emit("updateUserMsg", updateMsg);
        setallMessages((pre) => [
          ...pre,
          {
            sender: incomingMsgs?.sender,
            receiver: incomingMsgs?.receiver,
            message: incomingMsgs?.message,
            status: incomingMsgs?.status,
            time: incomingMsgs?.time,
            uniqueMessageID: incomingMsgs?.uniqueMessageID,
          },
        ]);
      }
      setincomingMsgs(null);
    }
  }, [incomingMsgs]);

  useEffect(() => {
    if (!Socket) return;
    Socket?.on("reciveMsg", async (data) => {
      setincomingMsgs(data);
    });
    Socket?.on("updatedMsg", async (data) => {
      setUpdatedMsg(data);
    });
    Socket?.on("AllupdatedMsg", async (data) => {
      if (
        data?.some(
          (x) =>
            x?.sender === userData?._id ||
            (x?.receiver === userData?._id &&
              x?.sender === selectedUser?._id) ||
            x?.receiver === selectedUser?._id
        )
      ) {
        setallMessages(data);
      }
    });
  }, [Socket]);

  useEffect(() => {
    if (updatedMsg !== null) {
      if (selectedUser?._id == updatedMsg?.receiver) {
        if (allMessages?.length > 0) {
          let findIndex = allMessages?.findIndex(
            (i) => i?.uniqueMessageID == updatedMsg?.uniqueMessageID
          );
          if (findIndex !== -1) {
            const updatedData = [...allMessages];
            updatedData.splice(findIndex, 1);
            updatedData.splice(findIndex, 0, updatedMsg);
            setTimeout(() => {
              setallMessages(updatedData);
            }, 100);
          }
        }
      }
    }
  }, [updatedMsg]);

  const onClose = () => {
    setOpen(false);
    setTimeout(() => {
      setSelectedUser(null);
    }, 100);
  };

  const onSend = () => {
    if (myMessages !== "") {
      const uniqueNumber = Date.now() + Math.random() + userData?._id;
      Socket.emit(
        "sendMessageToUser",
        {
          sender: userData?._id,
          receiver: selectedUser?._id,
          message: myMessages,
          uniqueMessageID: uniqueNumber,
          status: "wait",
        },
        (data) => {
          setallMessages((pre) => [
            ...pre,
            {
              sender: data?.sender,
              receiver: data?.receiver,
              message: data?.message,
              status: data?.status,
              time: data?.time,
              uniqueMessageID: data?.uniqueMessageID,
            },
          ]);
        }
      );
      setmyMessages("");
    }

  };

  const something = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      if (myMessages !== "" && myMessages !== null) {
        event.preventDefault();
        onSend();
      }
    }
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setmyMessages(myMessages + "\n");
    }
  };

  const getInitials = (name = "") => {
    const words = name.split(" ");

    if (words.length >= 3) {
      return `${words[0].charAt(0).toUpperCase()}${words[words.length - 1]
        .charAt(0)
        .toUpperCase()}`;
    } else {
      return words.map((word) => word.charAt(0).toUpperCase()).join("");
    }
  };

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) { }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [allMessages]);


  let findOnline = FiltredUers?.find((z) => z?._id === selectedUser?._id);
  return (
    <Drawer
      className="internal-chat-modal-main-wrapper"
      title={
        <>
          <p>{`${selectedUser?.name} (${selectedUser?.sudoName})`}</p>
          <span className={findOnline?.status == 22 ? "online" : findOnline?.status == 23 ? "away" : "offline"}>
            {findOnline?.status == 22 ? "Online" : findOnline?.status == 23 ? "Away" : "Offline"}
          </span>
        </>
      }
      closable
      destroyOnClose
      placement={"right"}
      width={450}
      maskClosable={false}
      mask={false}
      loading={Loading}
      onClose={onClose}
      open={open}
      footer={
        <div>
          <div style={{ marginBottom: 10 }}>
            <TextArea
              onKeyDown={(e) => something(e)}
              value={myMessages}
              onChange={(e) => setmyMessages(e.target.value)}
              rows={4}
              placeholder="Write Here..."
              maxLength={100}
            />
          </div>
          <div className="bottom_btn_main">
            {/* <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />

            <ImAttachment
              onClick={handleIconClick}
              style={{
                fontSize: 18,
                cursor: "pointer",
                color: "grey",
                marginTop: 6,
              }}
            /> */}
            <Button type="primary" onClick={() => onSend()}>
              Send
            </Button>
          </div>
        </div>
      }
    >
      <div>
        <>
          {Loading ? <p>Loading...</p> : <>
            {allMessages?.map((x, index) => {
              return (
                <div key={index}>
                  {x?.sender === userData?._id ? (
                    <div className="internal-chat-left">
                      <div className="otheruser-headers">
                        <p className="time">{moment(x?.time).fromNow()}</p>
                        <p className="name-chat">You</p>
                        <Tooltip placement="top" title={"You"}>
                          <div className="image" style={{ cursor: "pointer" }}>
                            <h4>{getInitials("You")}</h4>
                          </div>
                        </Tooltip>
                      </div>
                      <div className="user-chat-msgs">
                        <p>{x?.message}</p>
                      </div>
                      <p className="user-chat-status">
                        {x?.status === "wait" ? (
                          <MdOutlineAccessTime />
                        ) : x?.status === "sent" ? (
                          <IoCheckmark />
                        ) : x?.status === "delivered" ? (
                          <IoCheckmarkDoneSharp color="#a3a3a3" />
                        ) : (
                          <IoCheckmarkDoneSharp color="#009fff" />
                        )}
                      </p>
                    </div>
                  ) : (
                    <div className="internal-chat-right">
                      <div className="mychat-headers">
                        <Tooltip
                          placement="top"
                          title={
                            x?.receiver == selectedUser?._id
                              ? selectedUser?.sudoName
                              : selectedUser?.name
                          }
                        >
                          <div className="image">
                            <h4 style={{ cursor: "pointer" }}>
                              {getInitials(
                                x?.receiver == selectedUser?._id
                                  ? selectedUser?.sudoName
                                  : selectedUser?.name
                              )}
                            </h4>
                          </div>
                        </Tooltip>
                        <p className="name-chat">
                          {x?.receiver == selectedUser?._id
                            ? selectedUser?.sudoName
                            : selectedUser?.name}
                        </p>
                        <p className="time">{moment(x?.time).fromNow()}</p>
                      </div>
                      <div className="my-chat-msgs">
                        <p>{x?.message}</p>
                      </div>
                    </div>
                  )}

                </div>
              );
            })}
          </>}
        </>
        <div ref={messagesEndRef} />
      </div>
    </Drawer>
  );
}
