import React, { useState, useEffect } from "react";
import { Modal, Table, theme, Button, Space, Select, Form, Input, Spin, message, Popconfirm } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { useDispatch, useSelector } from "react-redux";
// import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import { getBannedVisitor, getBlockedCountries, getBlockedProperties } from "../../redux/Banned/BannedActions";
import moment from "moment/moment";
import UnblockModal from "../../components/unblockModal/UnblockModal";
import { Get_Brands_By_Id } from "../../redux/Brands/BrandsActions";
import { countries } from "../../helpers/countries";
import Ips from "./ips";
import BannedCountries from "./bannedCountries";

const Banned = () => {
  const userData = useSelector((state) => state.Auth.userData);
  const usertoken = useSelector((state) => state.Auth.token);
  const banneddata = useSelector((state) => state.BannedReducer.banneddata);
  const isLoadingbanned = useSelector((state) => state.BannedReducer.isLoadingbanned);
  const getbannedPropertiesdata = useSelector((state) => state.BannedReducer.getbannedPropertiesdata);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenStatus, setIsModalOpenStatus] = useState(false);
  const [userID, setuserID] = useState(false);
  const [allData, setAllData] = useState([]);
  const [bannedList, setbannedList] = useState("banned");
  const dispatch = useDispatch();

  useEffect(() => {
    let newData = {
      companyId: userData?.companyId,
      id: userData?._id
    };
    dispatch(Get_Brands_By_Id(newData, usertoken));
  }, []);

  useEffect(() => {
    let data = {
      Brands: userData?.assignBrands
    }
    dispatch(getBannedVisitor(data, usertoken));
  }, []);
  // getBlockedProperties

  const openModal = (id) => {
    setuserID(id);
    setIsModalOpen(true);
  };


  const showModal = () => {
    setIsModalOpenStatus(true);
  };
  // const navigate = useNavigate();
  const onSearch = (value) => {
    if (value !== null) {
      const filteredArray = banneddata?.filter(item => item?.unique_id?.includes(value));
      setAllData(filteredArray);
    }
    else {
      setAllData(banneddata);
    }
  };

  const handleChange = (value) => {
    if (value === "banned-ip") {
      let banneddata = {
        user_id: userData?._id
      }
      dispatch(getBlockedProperties(banneddata, usertoken));
    }
    else if (value === "banned-countries") {
      let banneddata = {
        user_id: userData?._id
      }
      dispatch(getBlockedCountries(banneddata, usertoken));
    }
    else {
      let data = {
        Brands: userData?.assignBrands
      }
      dispatch(getBannedVisitor(data, usertoken));
    }
    setbannedList(value);
  };

  useEffect(() => {
    if (banneddata?.length > 0) {
      setAllData(banneddata);
    }
    else {
      setAllData([]);
    }
  }, [banneddata]);

  const columns = [
    {
      title: "Visitor",
      dataIndex: "unique_id",
      render: (text) => <a>#[{text}]</a>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
    },
    {
      title: "Banned-By",
      dataIndex: "name",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (text) => <a> {moment(text).format("DD-MM-YYYY h:mm A")}</a>,
    },
    {
      title: "Action",
      dataIndex: "unique_id",
      render: (text) => <Button onClick={() => openModal(text)} type="dashed">Unblock</Button>,
    },
  ];

  return (
    <>
      <UnblockModal userID={userID} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
      <div className="topBtns">
        <div className="shortcut">
          <Space direction="vertical">
            <Search
              placeholder="Search"
              onSearch={onSearch}
              style={{
                width: 200,
              }}
            />
          </Space>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", }}>
          <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginRight: 20 }}> {bannedList === "banned-ip" ? <Button
            className="addShort"
            type="primary"
            onClick={() => showModal()}
          >
            Add Ip's
          </Button> : bannedList === "banned-countries" && <Button
            className="addShort"
            type="primary"
            onClick={() => showModal()}
          >
            Add Countries
          </Button>}</div>
          <div>
            <Select
              defaultValue={bannedList}
              style={{
                width: 180,
              }}
              onChange={handleChange}
              options={[
                {
                  value: 'banned',
                  label: 'Banned Visitor',
                },
                {
                  value: 'banned-ip',
                  label: `Banned Ip's`,
                },
                {
                  value: 'banned-countries',
                  label: 'Banned Countries',
                },
              ]}
            />
          </div>
        </div>
      </div>
      {bannedList === "banned-ip" ?
        <Ips
          isLoadingbanned={isLoadingbanned}
          data={getbannedPropertiesdata}
          isModalOpenStatus={isModalOpenStatus}
          setIsModalOpenStatus={setIsModalOpenStatus} />
        :
        bannedList === "banned-countries" ?
          <BannedCountries
            isLoadingbanned={isLoadingbanned}
            data={getbannedPropertiesdata}
            isModalOpenStatus={isModalOpenStatus}
            setIsModalOpenStatus={setIsModalOpenStatus} />
          :
          <div>
            <Table
              loading={isLoadingbanned}
              columns={columns}
              dataSource={allData?.map((item, index) => ({ ...item, key: index }))}
            />
          </div>
      }

    </>
  );
};

export default Banned;
