import { Post } from "../../helpers/apicalls/apicalls";
import * as actionTypes from "./BannedTypes";

export const getBannedVisitor = (data, token) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING_GET_BANNED,
    });
    Post("/agents/user/getBannedVisitor", data, token)
      .then((res) => {

        if (res.status) {
          dispatch({
            type: actionTypes.GET_BANNED_DATA_SUCCESS,
            payload: res.data
          });
        }
        else {
          dispatch({
            type: actionTypes.GET_BANNED_DATA_FAILED
          });
        }
      })
      .catch((err) => {

        dispatch({
          type: actionTypes.GET_BANNED_DATA_FAILED
        });
      });
  };
};

export const deleteBannedPerperties = (data, token, onSucces) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING_DELETE_BANNED_PROPERTIES,
    });
    Post("/agents/user/deleteBlockProperties", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.DELETE_BANNED_PROPERTIES_DATA_SUCCESS,
            payload: res.data
          });
          onSucces();
        }
        else {
          dispatch({
            type: actionTypes.DELETE_BANNED_PROPERTIES_DATA_FAILED
          });
        }
      })
      .catch((err) => {

        dispatch({
          type: actionTypes.DELETE_BANNED_PROPERTIES_DATA_FAILED
        });
      });
  };
};

export const unBlockVisitor = (data, token, onSuccess) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING_UNBLOCK,
    });
    Post("/agents/user/unBlockVisitor", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.UNBLOCK_DATA_SUCCESS,
            payload: res.data
          });
          onSuccess();
        }
        else {
          dispatch({
            type: actionTypes.UNBLOCK_DATA_FAILED
          });
        }
      })
      .catch((err) => {

        dispatch({
          type: actionTypes.UNBLOCK_DATA_FAILED
        });
      });
  };
};

export const AddBlockVisitorProperties = (data, token, handleCancel, message, onSuccess) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING_BANNED_PROPERTIES,
    });
    Post("/agents/user/addBlockProperties", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.BANNED_PROPERTIES_DATA_SUCCESS,
            payload: res.data
          });
          handleCancel();
          onSuccess();
          message.success(`${res?.message}`);
        }
        else {
          dispatch({
            type: actionTypes.BANNED_PROPERTIES_DATA_FAILED
          });
          message.error(`${res?.message}`);
        }
      })
      .catch((err) => {

        dispatch({
          type: actionTypes.BANNED_PROPERTIES_DATA_FAILED
        });
      });
  };
};

export const getBlockedProperties = (data, token, handleCancel, message) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING_GET_BANNED_PROPERTIES,
    });
    Post("/agents/user/getBlockProperties", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.GET_BANNED_PROPERTIES_DATA_SUCCESS,
            payload: res.data
          });
          handleCancel();
          message.success(`${res?.message}`);
        }
        else {
          dispatch({
            type: actionTypes.GET_BANNED_PROPERTIES_DATA_FAILED
          });
          message.error(`${res?.message}`);
        }
      })
      .catch((err) => {

        dispatch({
          type: actionTypes.GET_BANNED_PROPERTIES_DATA_FAILED
        });
      });
  };
};


export const getBlockedCountries = (data, token, handleCancel, message) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING_GET_BANNED_PROPERTIES,
    });
    Post("/agents/user/getBlockCountries", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.GET_BANNED_PROPERTIES_DATA_SUCCESS,
            payload: res.data
          });
          handleCancel();
          message.success(`${res?.message}`);
        }
        else {
          dispatch({
            type: actionTypes.GET_BANNED_PROPERTIES_DATA_FAILED
          });
          message.error(`${res?.message}`);
        }
      })
      .catch((err) => {

        dispatch({
          type: actionTypes.GET_BANNED_PROPERTIES_DATA_FAILED
        });
      });
  };
};

export const AddBlockVisitorCountries = (data, token, handleCancel, message, onSuccess) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING_BANNED_PROPERTIES,
    });
    Post("/agents/user/addBlockCountries", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.BANNED_PROPERTIES_DATA_SUCCESS,
            payload: res.data
          });
          handleCancel();
          onSuccess();
          message.success(`${res?.message}`);
        }
        else {
          dispatch({
            type: actionTypes.BANNED_PROPERTIES_DATA_FAILED
          });
          message.error(`${res?.message}`);
        }
      })
      .catch((err) => {

        dispatch({
          type: actionTypes.BANNED_PROPERTIES_DATA_FAILED
        });
      });
  };
};

export const deleteBannedCountries = (data, token, onSucces) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING_DELETE_BANNED_PROPERTIES,
    });
    Post("/agents/user/deleteBlockCountries", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.DELETE_BANNED_PROPERTIES_DATA_SUCCESS,
            payload: res.data
          });
          onSucces();
        }
        else {
          dispatch({
            type: actionTypes.DELETE_BANNED_PROPERTIES_DATA_FAILED
          });
        }
      })
      .catch((err) => {

        dispatch({
          type: actionTypes.DELETE_BANNED_PROPERTIES_DATA_FAILED
        });
      });
  };
};


export const EditBlockVisitorCountries = (data, token, handleCancel, message, onSuccess) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.IS_LOADING_BANNED_PROPERTIES,
    });
    Post("/agents/user/editBlockCountries", data, token)
      .then((res) => {
        if (res.status) {
          dispatch({
            type: actionTypes.BANNED_PROPERTIES_DATA_SUCCESS,
            payload: res.data
          });
          handleCancel();
          onSuccess();
          message.success(`${res?.message}`);
        }
        else {
          dispatch({
            type: actionTypes.BANNED_PROPERTIES_DATA_FAILED
          });
          message.error(`${res?.message}`);
        }
      })
      .catch((err) => {

        dispatch({
          type: actionTypes.BANNED_PROPERTIES_DATA_FAILED
        });
      });
  };
};
