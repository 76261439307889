import React, { useState } from 'react'
import AdminLayout from '../adminComponents/adminLayout/adminLayout';
import { Route, Routes } from "react-router-dom";
import AdminHome from '../adminPages/adminHome';
import Plans from '../adminPages/plans/plans';
import CreatePlan from '../adminPages/plans/createPlan';
import EditPlan from '../adminPages/plans/editPlan';
import Packages from '../adminPages/packages/packages';
import CreatePackage from '../adminPages/packages/createPackage';
import EditPackage from '../adminPages/packages/EditPackage';
import Features from '../adminPages/features';

export default function SuperAdminRoutes() {
    const [collapsed, setCollapsed] = useState(false);
    return (
        <AdminLayout setCollapsed={setCollapsed} collapsed={collapsed}>
            <Routes>
                <Route index path="/" element={<AdminHome />} />
                <Route path="/plans-packages" element={<Plans />} />
                <Route path="/create-plan" element={<CreatePlan />} />
                <Route path="/edit-plan" element={<EditPlan />} />
                <Route path="/packages" element={<Packages />} />
                <Route path="/create-package" element={<CreatePackage />} />
                <Route path="/edit-package" element={<EditPackage />} />
                <Route path="/features" element={<Features />} />

                <Route path="*" element={<AdminHome />} />
            </Routes>
        </AdminLayout>
    )
}
