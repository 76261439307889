import React, { useState, useEffect } from "react";
import { Modal, Table, theme, Button, Space, Select, Form, Input, Spin, message, Popconfirm } from "antd";
import moment from "moment/moment";
import { LoadingOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { AddBlockVisitorCountries, deleteBannedCountries, EditBlockVisitorCountries, getBlockedCountries, getBlockedProperties } from "../../redux/Banned/BannedActions";
import { useDispatch, useSelector } from "react-redux";
import { countries } from "../../helpers/countries";

export default function BannedCountries({ isLoadinggetbannedProperties, data, isModalOpenStatus, setIsModalOpenStatus }) {
    const dispatch = useDispatch();
    const usertoken = useSelector((state) => state.Auth.token);
    const userData = useSelector((state) => state.Auth.userData);
    const brandsData = useSelector((state) => state.Brands.data);
    const [countriesData, setCountriesData] = useState([]);
    const [isEdit, setisEdit] = useState(false);
    const [EditData, setEditData] = useState(null);
    const isLoadingbannedProperties = useSelector((state) => state.BannedReducer.isLoadingbannedProperties);
    const [form] = Form.useForm();

    const columnsProperties = [
        {
            title: "Country",
            // dataIndex: "countries",
            // render: (text) => {
            //     let countryData = countries?.filter((x) => text?.some((y) => y === x?.cca2));
            //     return countryData?.map((x, index) => {
            //         return <p key={index}>{x?.name}</p>
            //     })
            // },
            dataIndex: "countries",
            render: (text) => {
                let countryData = countries?.filter((x) => text?.some((y) => y === x?.cca2));
                return (
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', width: '206px', justifyContent: 'flex-start' }}>
                        {countryData?.map((x, index) => (
                            <p key={index} style={{ width: 'auto', margin: 0, backgroundColor: '#75848769', padding: '2px 8px', borderRadius: '4px' }}>{x?.name}</p>
                        ))}
                    </div>
                );
            }
        },
        {
            title: "Brand/Wensite",
            dataIndex: "brandInfo",
            render: (text) => <p>{text?.brandname}</p>,
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            render: (text) => <a> {moment(text).format("DD-MM-YYYY h:mm A")}</a>,
        },
        {
            title: "Action",
            dataIndex: "_id",
            render: (text, data) => <div>
                <Popconfirm
                    placement="topLeft"
                    title="Delete the banned property"
                    description="Are you sure to delete this banned property?"
                    onConfirm={() => ondelete(text)}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteOutlined />
                </Popconfirm>
                <EditOutlined style={{ marginLeft: "10px" }} onClick={() => onEdit(data)} />
            </div>,
        },
    ];

    const onEdit = (data) => {
        setisEdit(true);
        setEditData(data);
        setIsModalOpenStatus(true);
        form.setFieldsValue({
            token: data?.brand,
            value: data?.countries
        });
    };

    useEffect(() => {
        let newData = countries?.map((x) => {
            return {
                value: x?.cca2,
                label: x?.name,
            }
        });
        setCountriesData(newData);
    }, []);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 20,
                color: "#ffffff",
                margin: "0px 25px",
            }}
            spin
        />
    );

    const onFinish = (e) => {
        if (isEdit) {
            let data = {
                value: e?.value,
                brand: e?.token,
                user_id: userData?._id,
                _id: EditData?._id
            };
            dispatch(EditBlockVisitorCountries(data, usertoken, handleCancel, message, onSuccess));
        } else {
            let data = {
                value: e?.value,
                brand: e?.token,
                user_id: userData?._id
            };
            dispatch(AddBlockVisitorCountries(data, usertoken, handleCancel, message, onSuccess));
        }

    };

    const ondelete = (id) => {
        let data = {
            id: id
        }
        dispatch(deleteBannedCountries(data, usertoken, onSuccess));
    };
    const onSuccess = () => {
        let banneddata = {
            user_id: userData?._id
        }
        dispatch(getBlockedCountries(banneddata, usertoken));
        form.resetFields();
        setisEdit(false);
        setEditData(null);
    };

    const handleCancel = () => {
        setIsModalOpenStatus(false);
        form.resetFields();
        setisEdit(false);
        setEditData(null);
    };

    return (
        <div>
            <Modal maskClosable={false} title="Banned Properties" open={isModalOpenStatus} onCancel={() => handleCancel()}
                footer={false}>
                <Form
                    initialValues={{
                        BannedBy: 'ip-address',
                    }}
                    form={form}
                    onFinish={onFinish}
                    layout={"vertical"}
                    autoComplete="off"
                >
                    <Form.Item
                        style={{ marginBottom: 10 }}
                        name="token"
                        rules={[{ required: true }]}
                        label="Website/Brand">
                        <Select
                            placeholder="Select Website/Brand"
                        >
                            {brandsData?.map((i, index) => (
                                <Select.Option key={index} value={i?._id}>{i?.brandname}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item rules={[
                        { required: true },
                    ]}
                        style={{ marginBottom: 10 }} label="Countries" name="value">
                        <Select
                            mode="tags"
                            style={{
                                width: '100%',
                            }}
                            placeholder="Select Country"
                            // onChange={handleChange}
                            options={countriesData}
                        />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            span: 24,
                        }}
                    >  <Button style={{ marginRight: 10 }} key="cancel" onClick={() => handleCancel()}>
                            Cancel
                        </Button>

                        <Button disabled={isLoadingbannedProperties} type="primary" htmlType="submit">
                            {isLoadingbannedProperties ? <Spin indicator={antIcon} style={{ padding: "0px 17px 0px 17px" }} /> : "Save Changes"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Table
                loading={isLoadinggetbannedProperties}
                columns={columnsProperties}
                dataSource={data?.map((item, index) => ({ ...item, key: index }))}
            />
        </div>
    )
}
