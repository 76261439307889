import {
  AppstoreOutlined,
  HomeOutlined,
  SettingOutlined,
} from "@ant-design/icons";
// import { Badge, Button } from "antd";

export const AdminSideBar = (
  navigate,
  setselectedKey,
) => [
    {
      key: "/",
      icon: <HomeOutlined />,
      label: "Home",
      onClick: () => {
        setselectedKey("/");
        navigate("/");
      },
    },
    {
      key: "/plans-packages",
      icon: <AppstoreOutlined />,
      label: "Plans & Packages",
      onClick: () => {
        setselectedKey("/plans-packages");
        navigate("/plans-packages");
      },
    },
    {
      key: "/features",
      icon: <AppstoreOutlined />,
      label: "Features",
      onClick: () => {
        setselectedKey("/features");
        navigate("/features");
      },
    },
    {
      key: "/settings",
      icon: <SettingOutlined />,
      label: "Settings",
      children: [
        {
          key: "/personal",
          label: "Personal",
          onClick: () => {
            setselectedKey("/personal");
            navigate("/personal");
          },
        },
        // {
        //   key: "/triggers",
        //   label: "Triggers",
        //   onClick: () => {
        //     navigate("/triggers");
        //   },
        // },
      ],
    },
  ];
