import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Popconfirm, Space, Table } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import AddModal from './addModal';

export default function Features() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const columns = [
        {
            title: "Title",
            dataIndex: "title",
        },
        {
            title: "Active",
            dataIndex: "isActive",
        },
        {
            title: "Value",
            dataIndex: "hasValue",
        },
    ];
    return (
        <>
            <AddModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            <div className="topbtn">
                <Button type="primary" onClick={() => setIsModalOpen(true)}>
                    Create Features
                </Button>
            </div>
            <Table
                // loading={planLoading}
                pagination={false}
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={[]}
            />
        </>
    )
}
