import React, { useContext } from "react";
import { Layout } from "antd";
import Footers from "../../components/footers/footers";
// import Marquee from 'react-fast-marquee';
import AdminSidebar from "../adminSideBar/adminSidebar";
import AdminHeaders from "../adminHeaders/adminHeaders";
const { Content } = Layout;
const AdminLayout = ({ children, collapsed, setCollapsed }) => {
  return (
    <Layout className="layout">
      <AdminSidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout style={{ paddingLeft: collapsed ? 80 : 200, transition: 'margin-left 0.5s', position: "relative" }} className="site-layout">
        <div style={{ zIndex: 1 }}>
          <AdminHeaders />
        </div>
        <Content className="content">
          {children}
        </Content>
        <Footers />
      </Layout>
    </Layout>

  );
};

export default AdminLayout;
