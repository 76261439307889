import React, { useState, useEffect, useContext } from "react";
import Tabs from "react-draggable-tabs";
import "./bottomTabs.scss";
import { BellFilled } from "@ant-design/icons";
import { SocketContext } from '../../context/socketContext';
import { useDispatch, useSelector } from "react-redux";
import { onLeaveServed } from "../../redux/VisitorsJoined/VisitorsJoinedActions";

export default function BottomTabs({
  setmessageId,
  messageId,
  AllActiveVisitors,
  setAllActiveVisitors,
  collapsed,
}) {
  const [tabs, setTabs] = useState([]);
  const { Socket } = useContext(SocketContext);
  const userData = useSelector((state) => state.Auth.userData);
  const servedData = useSelector((state) => state.VisitorReducer.data);
  const dispatch = useDispatch();
  const toggleModal = (id) => {
    setAllActiveVisitors((visitors) =>
      visitors?.map((visitor) =>
        visitor.unique_id == id
          ? { ...visitor, isOpen: !visitor.isOpen }
          : visitor
      )
    );
    if (AllActiveVisitors.some((visitor) => visitor.unique_id === id)) {
      setAllActiveVisitors((visitors) =>
        visitors.filter((visitor) => visitor.unique_id !== id)
      );
      let findvisitor = AllActiveVisitors?.find((x) => x?.unique_id == id);
      if (findvisitor !== null && findvisitor?.servedBy?.some((x) => x?.id == userData?._id)) {
        LeaveRoom(findvisitor);
        dispatch(onLeaveServed(findvisitor, servedData));
      }
    }
  };

  const LeaveRoom = (visitor) => {
    let data = {
      agentId: userData?._id,
      visitorId: visitor?.unique_id,
      websiteToken: visitor?.websiteToken,
      role: userData?.role
    }
    Socket.emit("agentLeaveRoom", data);
  };

  const Maximized = (id) => {
    let findID = messageId?.filter((x) => x?.unique_id != id);
    setmessageId(findID);
    setAllActiveVisitors((visitors) =>
      visitors.map((visitor) =>
        visitor.unique_id === id
          ? { ...visitor, minimized: false, active: true, zIndex: 1 }
          : { ...visitor, zIndex: 0 }
      )
    );
  };

  useEffect(() => {
    if (AllActiveVisitors?.length > 0) {
      let find = AllActiveVisitors?.map((x) => {
        let findId = messageId?.find((i) => i?.unique_id == x?.unique_id);
        return {
          id: x?.unique_id,
          active: true,
          content: (
            <div className={`tab_bottom_content ${findId && "blink"}`}>
              {x?.name != "" ? (
                <p>
                  {x?.name}
                  {findId ? (
                    <span className="span_noti">
                      <span className="span_noti_relative">
                        {findId && <BellFilled />}
                        <p className="header-general-pulse">{findId?.value}</p>
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              ) : (
                <p>
                  {x?.unique_id}
                  {findId ? (
                    <span className="span_noti">
                      <span className="span_noti_relative">
                        {findId && <BellFilled />}
                        <p className="header-general-pulse">{findId?.value}</p>
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              )}
            </div>
          ),
          ...x,
        };
      });
      setTabs(find);
    } else {
      setTabs([]);
    }
  }, [AllActiveVisitors]);

  useEffect(() => {
    if (messageId?.length > 0) {
      let find = AllActiveVisitors?.map((x) => {
        let findId = messageId?.find((i) => i?.unique_id == x?.unique_id);
        return {
          id: x?.unique_id,
          active: true,
          content: (
            <div className={`tab_bottom_content ${findId && "blink"}`}>
              {x?.name != "" ? (
                <p>
                  {x?.name}
                  {findId ? (
                    <span className="span_noti">
                      <span className="span_noti_relative">
                        {findId && <BellFilled />}
                        <p className="header-general-pulse">{findId?.value}</p>
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              ) : (
                <p>
                  {x?.unique_id}
                  {findId ? (
                    <span className="span_noti">
                      <span className="span_noti_relative">
                        {findId && <BellFilled />}
                        <p className="header-general-pulse">{findId?.value}</p>
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </p>
              )}
            </div>
          ),
          ...x,
        };
      });
      setTabs(find);
    }
  }, [messageId]);

  const moveTab = (dragIndex, hoverIndex) => {
    setTabs((prevTabs) => {
      const newTabs = [...prevTabs];
      newTabs.splice(hoverIndex, 0, newTabs.splice(dragIndex, 1)[0]);
      return newTabs;
    });
  };

  const selectTab = (selectedIndex, selectedID) => {
    setTabs((prevTabs) => {
      return prevTabs.map((tab) => ({
        ...tab,
        active: tab.id === selectedID,
      }));
    });
    Maximized(selectedID);
  };

  const closedTab = (removedIndex, removedID) => {
    setTabs((prevTabs) => {
      const newTabs = [...prevTabs];
      newTabs.splice(removedIndex, 1);

      if (prevTabs[removedIndex].active && newTabs.length !== 0) {
        const newActive = removedIndex === 0 ? 0 : removedIndex - 1;
        newTabs[newActive].active = true;
      }

      return newTabs;
    });
    toggleModal(removedID);
  };

  // const activeTab = tabs.filter((tab) => tab.active === true);

  return (
    <div
      style={{ paddingLeft: collapsed ? "80px" : "200px" }}
      className="bottom_visitors_tab_main"
    >
      <Tabs
        moveTab={moveTab}
        selectTab={selectTab}
        closeTab={closedTab}
        tabs={tabs}
      ></Tabs>
    </div>
  );
}
