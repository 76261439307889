import React, { useState, useEffect } from "react";
import { Table, Badge, Tabs, Button, DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import TransTab from "./TransTab";
import UserTab from "./UserTab";
import { CloseOutlined, LeftCircleOutlined } from "@ant-design/icons";
import { getOverAllHistory, getVisitorAllHistory } from "../../redux/AllHistory/AllHistoryActions";
import { updateTickets } from "../../redux/Tickets/TicketsActions";
import { useNavigate } from "react-router-dom";

const AllHistory = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.Auth.userData);
    const usertoken = useSelector((state) => state.Auth.token);
    const overAllUsers = useSelector((state) => state.Users.overAllUsers);
    const visitorData = useSelector((state) => state.ALLHistoryReducer.overAllData);
    const isLoadingH = useSelector((state) => state.ALLHistoryReducer.isLoadingOverAll);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isShow, setisShow] = useState(false);
    const [colorCode, setColorCode] = useState(null);
    const { TabPane } = Tabs;
    const [selectedData, setselectedData] = useState(null);
    const navigate = useNavigate();

    const [filteredDate, setFilteredDate] = useState(null);

    const handleDateChange = (date, dateString) => {
        setFilteredDate(dateString);
    };

    useEffect(() => {
        let allTokens = userData?.assignBrands?.map((i) => i?.token);
        let Data = {
            brand_token: allTokens,
        };
        dispatch(getOverAllHistory(Data, usertoken));
    }, []);

    const columns = [
        {
            title: "Name",
            dataIndex: "visitor",
            width: 150,
            render: (text, item) => {
                return <div className="visitorIcon">{text?.name ? text?.name : `#[${text?.unique_id}]`}</div>
            }
        },
        {
            title: "Agent",
            // dataIndex: "visitor",
            width: 150,
            render: (text) => {
                const agentMessages = text?.messages?.filter(item => item?.senderID !== text?.visitor?.unique_id);
                const lastAgentMessage = agentMessages[agentMessages?.length - 1];
                let filtredUser = overAllUsers?.filter((i) => i?._id == lastAgentMessage?.senderID);
                return <div className="visitorIcon">{filtredUser?.[0]?.sudoName}</div>
            }
        },
        {
            title: 'Time',
            dataIndex: 'visitor',
            width: 240,
            render: (text) => (
                <div className="visitorIcon">
                    {moment(text?.start_time).format("hh:mm A  DD-MM-YYYY")}
                </div>
            ),
            filteredValue: filteredDate ? [moment(filteredDate, 'DD-MM-YYYY')] : null,
            filterDropdown: () => (
                <div style={{ padding: 8 }}>
                    <DatePicker format="DD-MM-YYYY"
                        onChange={handleDateChange} />
                </div>
            ),
            onFilter: (value, record) => {
                const selectedDate = moment(value).format("DD-MM-YYYY"); // Format selected date
                const recordDate = moment(record?.visitor?.start_time).format("DD-MM-YYYY"); // Format date in record
                return recordDate === selectedDate;
            },
        },
        {
            title: "Messages",
            render: (text) => {
                const fetchMessageData = () => {
                    return text?.messages
                        .sort((x, y) => {
                            return new Date(x?.time).toUTCString() < new Date(y?.time).toUTCString()
                                ? 1
                                : -1;
                        })
                        .reverse();
                };
                const textTypeArray = fetchMessageData()?.filter(item => item?.type === 'text' || item?.to);

                return <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className="msgBadges">
                        {fetchMessageData()?.length > 0 && (
                            fetchMessageData()?.length
                        )}
                    </div>
                    <div>
                        {textTypeArray?.[textTypeArray?.length - 1]?.message !== "" ? (
                            textTypeArray?.[textTypeArray?.length - 1]?.message
                        ) : (
                            textTypeArray?.[textTypeArray?.length - 1]?.file?.filename
                        )}
                    </div>

                </div>
            }
        },
    ];

    useEffect(() => {
        if (isShow) {
            setColorCode(Math.random().toString(16).substr(-6));
        }
    }, [isShow]);

    const handleRowClick = (record) => {
        setselectedData(record);
        upDateIsRead(record?.visitor?.unique_id, record?.isRead);
        setisShow(true);
    };

    const handleCloseSidebar = () => {
        setisShow(false);
    };

    const upDateIsRead = (id, isRead) => {
        if (!isRead) {
            let data = {
                ticketsIds: id,
                userId: userData?._id
            }
            dispatch(updateTickets(data, usertoken, afterSuccess));
        }
    };
    const afterSuccess = () => {
        let allTokens = userData?.assignBrands?.map((i) => i?.token);
        let Data = {
            brand_token: allTokens,
        };
        dispatch(getVisitorAllHistory(Data, usertoken));

    };

    const rowClassName = (record) => {
        return record?.visitor?.isRead === false ? "bold-row" : "";
    };

    return (
        <div className="table-container-sidebarnew">
            <div className="topbtns">
                <Button onClick={() => navigate(-1)} icon={<LeftCircleOutlined />} type="primary" >
                    Back
                </Button>
                <h3 style={{ margin: 0, textAlign: "center", paddingTop: 5 }}>All Visitors</h3>
                <div>
                </div>
            </div>

            <Table
                loading={isLoadingH}
                dataSource={visitorData?.map((item, index) => ({ ...item, key: index }))}
                columns={columns}
                rowClassName={rowClassName}
                pagination={{
                    showTotal: (total, range) => `Total ${total} records`,
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => handleRowClick(record),
                    };
                }}
                style={{ marginTop: "20px" }}
            />
            <div>
                <div className={`sidebarnew ${isShow ? "open" : "closed"}`}>
                    <a className="crossBtn" onClick={handleCloseSidebar}>
                        <CloseOutlined />
                    </a>
                    <div className="currentHistory">
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Transcript" key="1">
                                <TransTab selectedData={selectedData} colorCode={colorCode} />
                            </TabPane>
                            <TabPane tab="User" key="2">
                                <UserTab selectedData={selectedData} />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllHistory;