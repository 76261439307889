export const IS_LOADING = "IS_LOADING";
export const Users_DATA_SUCCESS = "Users_DATA_SUCCESS";
export const Users_DATA_FALED = "Users_DATA_FALED";

export const AGENT_BY_BRAND_SUCCESS = "AGENT_BY_BRAND_SUCCESS";

export const GET_USER_DATA_SUCCESS_LOADING = "GET_USER_DATA_SUCCESS_LOADING";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILED = "GET_USER_DATA_FAILED";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";


export const IS_USER_ALL_LOADING = "IS_USER_ALL_LOADING";
export const GET_USER_ALL_SUCCESS = "GET_USER_ALL_SUCCESS";
export const GET_USER_ALL_FAILED = "GET_USER_ALL_FAILED";


export const IS_LOADING_UI = "IS_LOADING_UI";
export const UI_SUCCESS = "UI_SUCCESS";
export const UI_FALED = "UI_FALED";


export const IS_LOADING_USER_MESSAGES = "IS_LOADING_USER_MESSAGES";
export const USER_MESSAGES_SUCCESS = "USER_MESSAGES_SUCCESS";
export const USER_MESSAGES_FALED = "USER_MESSAGES_FALED";
