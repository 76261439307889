import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export function LineArea({ AnalyticsData }) {

  let allLable = AnalyticsData?.TotalURLs.length > 0
    ? AnalyticsData?.TotalURLs.map((i) => {
      const label = i?._id || '';
      return label.length > 8 ? `${label.substring(0, 8)}...` : label;
    })
    : ["There is no Data Currently"];
  let allData = AnalyticsData?.TotalURLs.length > 0 ? AnalyticsData?.TotalURLs?.map((i) => i?.count) : [0];

  const fulllables = AnalyticsData?.TotalURLs.map(i => i?._id) || ['There is no Data Currently'];
  const generateRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b}, 1)`;
  };

  const backgroundColors = allLable.map(() => generateRandomColor());
  const borderColors = backgroundColors.map(color => color.replace(/[^,]+(?=\))/, '1'));

  const data = {
    labels: allLable,
    datasets: [
      {
        label: 'WebPage Visited',
        data: allData,
        fill: true,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 2,
      },
    ],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Allows the canvas to grow with its container
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = fulllables[context.dataIndex] || '';
            const value = context.raw || 0;
            return `${label}: ${value}`;
          }
        }
      }
    }
  };
  return <div style={{ height: 510 }}>
    <Line data={data} options={options} />
  </div>;
}
