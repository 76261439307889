import React, { useContext, useState, useEffect } from "react";
import { Layout, Menu, Badge } from "antd";
import { useDispatch, useSelector } from "react-redux";
import logo1 from "../../assets/image/ConvoBird-logo-final-03.png";
import logo2 from "../../assets/image/bird.png"
import { useLocation, useNavigate, } from "react-router-dom";
import { SocketContext } from "../../context/socketContext";
import UserChatDrawer from "../userChatDrawer";
import { GetAllMessages } from "../../redux/Users/UsersActions";
import { UserSideBar } from "./userSideBar";

const Sidebarr = ({ collapsed, setCollapsed }) => {
  //hooks
  const location = useLocation();
  let navigate = useNavigate();
  const {
    Socket,
    setFiltredUsers,
    FiltredUers,
    selectedUserChat,
    setSelectedUserChat,
    unSeenChat,
    setUnSeenChat,
    openUserChat,
    setOpenUserChat
  } = useContext(SocketContext);
  const ticketsData = useSelector((state) => state.TicketsReducer.data);
  const overAllUsers = useSelector((state) => state.Users.overAllUsers);
  const usertoken = useSelector((state) => state.Auth.token);
  const messagesData = useSelector((state) => state.Users.messagesData);
  // const visitorData = useSelector((state) => state.ALLHistoryReducer.dataH);
  const userData = useSelector((state) => state.Auth.userData);
  const [AllUsers, setAllUsers] = useState([]);
  const [msgRecived, setMsgRecived] = useState(null)
  const [selectedKey, setselectedKey] = useState(null)
  let isReadData = ticketsData?.filter((i) => i?.isRead === false);
  const currentPath = location.pathname;
  const { Sider } = Layout;
  const dispatch = useDispatch();

  useEffect(() => {
    let data = {
      id: userData?._id
    }
    dispatch(GetAllMessages(data, usertoken));
  }, []);

  useEffect(() => {
    if (!Socket) return;
    Socket?.on("MyTeam", async (data) => {
      setAllUsers(data);
    });
    Socket?.on("reciveMsg", async (data) => {
      setMsgRecived(data);

    });
  }, [Socket]);
  useEffect(() => {
    if (msgRecived !== null) {
      if (selectedUserChat === null || msgRecived?.sender !== selectedUserChat?._id) {
        const index = unSeenChat?.findIndex(item => item?.userID == msgRecived?.sender);
        if (index !== -1) {
          const newData = [...unSeenChat];
          newData[index] = { ...newData[index], value: newData[index]?.value + 1 };
          let updateMsg = msgRecived;
          updateMsg.status = "delivered";
          Socket?.emit("updateUserMsg", updateMsg);
          setUnSeenChat(newData);
        } else {
          setUnSeenChat(prevData => [...prevData, { userID: msgRecived?.sender, value: 1 }]);
          let updateMsg = msgRecived;
          updateMsg.status = "delivered";
          Socket?.emit("updateUserMsg", updateMsg);
        }
      }
      setMsgRecived(null);
    }
  }, [msgRecived]);


  useEffect(() => {
    if (overAllUsers?.length > 0) {
      // Separate matched and non-matched users
      const matchedUsers = [];
      const nonMatchedUsers = [];

      overAllUsers.forEach((x) => {
        const matchedUser = AllUsers.find((y) => y.key === x._id);
        if (matchedUser) {
          matchedUsers.push({
            ...x,
            status: matchedUser.status
          });
        } else {
          nonMatchedUsers.push({
            ...x,
            status: 24
          });
        }
      });
      // Combine matched users first, then non-matched users
      const newData = [...matchedUsers, ...nonMatchedUsers];
      setFiltredUsers(newData);
    }
  }, [overAllUsers, AllUsers]);

  const maxVisibleUsers = 5; // Maximum number of users to display before "Show More"
  const visibleUsers = FiltredUers?.slice(0, maxVisibleUsers);
  return (
    <Sider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 1
      }}
      className="sider"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div onClick={() => navigate("/")} className={collapsed ? "minlogo" : "logo"} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {collapsed ? <img src={logo2} style={{ height: 'auto', width: 35, marginLeft: 2, marginRight: 2 }} />
          :
          <img src={logo1} style={{ height: 'auto', width: "80%", marginLeft: 2, marginRight: 2 }} />}
      </div>
      <Menu

        className="menu"
        theme="dark"
        selectedKeys={selectedKey !== null ? [selectedKey] : [currentPath]}
        defaultOpenKeys={['14']}
        mode="inline"
        items={UserSideBar(navigate, FiltredUers, setselectedKey, isReadData, setOpenUserChat, setSelectedUserChat, unSeenChat, visibleUsers, maxVisibleUsers, messagesData)}
      />
      <UserChatDrawer
        unSeenChat={unSeenChat}
        setUnSeenChat={setUnSeenChat}
        setSelectedUser={setSelectedUserChat}
        selectedUser={selectedUserChat}
        open={openUserChat}
        setOpen={setOpenUserChat} />
    </Sider>
  );
};

export default Sidebarr;
