import * as actionTypes from "./UsersTypes";

const INTIAL_STATE = {
  data: null,
  isLoading: false,
  isLoadingUI: false,
  customizeData: [],
  BrandAgents: [],
  // allUsers: [],
  overAllUsers: [],
  // isLoadingUsers: false,
  isLoadingMessages: false,
  messagesData: [],
  isLoadingUserALL: false
};

const UsersReducer = (state = INTIAL_STATE, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.IS_LOADING:
      return {
        ...state,
        isLoading: true
      };

    case actionTypes.Users_DATA_SUCCESS:
      return {
        ...state,
        data: payload,
        isLoading: false
      };
    case actionTypes.Users_DATA_FALED:
      return {
        ...state,
        isLoading: false
      };

    // Agents by brand Reducer
    case actionTypes.AGENT_BY_BRAND_SUCCESS:
      return {
        ...state,
        BrandAgents: payload,
        isLoading: false
      };

    // case actionTypes.GET_USER_DATA_SUCCESS_LOADING:
    //   return {
    //     ...state,
    //     isLoadingUsers: true
    //   };

    // case actionTypes.GET_USER_DATA_SUCCESS:
    //   return {
    //     ...state,
    //     allUsers: payload,
    //     isLoadingUsers: false
    //   };

    // case actionTypes.GET_USER_DATA_FAILED:
    //   return {
    //     ...state,
    //     isLoadingUsers: false
    //   };

    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allUsers: payload
      };

    case actionTypes.UPDATE_USER_FAILED:
      return {
        ...state,
        isLoading: false
      };

    ///////////////////

    case actionTypes.IS_USER_ALL_LOADING:
      return {
        ...state,
        isLoadingUserALL: true
      };
    case actionTypes.GET_USER_ALL_SUCCESS:
      return {
        ...state,
        overAllUsers: payload,
        isLoadingUserALL: false
      };
    case actionTypes.GET_USER_ALL_FAILED:
      return {
        ...state,
        isLoadingUserALL: false
      };

    ///////////////////////
    case actionTypes.IS_LOADING_UI:
      return {
        ...state,
        isLoadingUI: true
      };

    case actionTypes.UI_SUCCESS:
      return {
        ...state,
        isLoadingUI: false,
        customizeData: payload
      };
    case actionTypes.UI_FALED:
      return {
        ...state,
        isLoadingUI: false
      };



    /////////////////////// Messages 
    case actionTypes.IS_LOADING_USER_MESSAGES:
      return {
        ...state,
        isLoadingMessages: true
      };
    case actionTypes.USER_MESSAGES_SUCCESS:
      return {
        ...state,
        isLoadingMessages: false,
        messagesData: payload
      };
    case actionTypes.USER_MESSAGES_FALED:
      return {
        ...state,
        isLoadingMessages: false
      };


    default:
      return state;
  }
};

export default UsersReducer;
