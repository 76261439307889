import React, { useState } from 'react';
import { Button, Modal, Checkbox, Form, Input } from 'antd';


const AddModal = ({ isModalOpen, setIsModalOpen }) => {
    const [isChecked, setIsChecked] = useState(true)
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onFinish = (values) => {
        console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <>
            <Modal footer={null} title="Basic Modal" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <>
                    <Form
                        name="basic"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Feature Name"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your Feature Name!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item name="hasValue" valuePropName="checked" label={null}>
                            <Checkbox onChange={(e) => setIsChecked(e.target.checked)} checked={isChecked} >Value</Checkbox>
                        </Form.Item>

                        <Form.Item
                            label="Value"
                            name="value"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Value!',
                                },
                            ]}
                        >
                            <Input disabled={!isChecked} />
                        </Form.Item>
                        <Form.Item label={null}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            </Modal>
        </>
    );
};
export default AddModal;