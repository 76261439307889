import React, { useState, useEffect } from "react";
import { Modal, Table, theme, Button, Space, Select, Form, Input, Spin, message, Popconfirm } from "antd";
import moment from "moment/moment";
import { LoadingOutlined, DeleteOutlined } from "@ant-design/icons";
import { AddBlockVisitorProperties, deleteBannedPerperties, getBlockedProperties } from "../../redux/Banned/BannedActions";
import { useDispatch, useSelector } from "react-redux";

export default function Ips({ isLoadinggetbannedProperties, data, isModalOpenStatus, setIsModalOpenStatus }) {
    const dispatch = useDispatch();
    const usertoken = useSelector((state) => state.Auth.token);
    const userData = useSelector((state) => state.Auth.userData);
    const brandsData = useSelector((state) => state.Brands.data);
    const isLoadingbannedProperties = useSelector((state) => state.BannedReducer.isLoadingbannedProperties);
    const [form] = Form.useForm();

    const columnsProperties = [
        {
            title: "Ip",
            dataIndex: "value",
        },
        {
            title: "Brand/Wensite",
            dataIndex: "brandInfo",
            render: (text) => <p>{text?.brandname}</p>,
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            render: (text) => <a> {moment(text).format("DD-MM-YYYY h:mm A")}</a>,
        },
        {
            title: "Action",
            dataIndex: "_id",
            render: (text) => <div>
                <Popconfirm
                    placement="topLeft"
                    title="Delete the banned property"
                    description="Are you sure to delete this banned property?"
                    onConfirm={() => ondelete(text)}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteOutlined />
                </Popconfirm>
            </div>,
        },
    ];

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 20,
                color: "#ffffff",
                margin: "0px 25px",
            }}
            spin
        />
    );

    const onFinish = (e) => {
        let FindBrand = brandsData?.find((i) => i?.token == e?.token);
        let data = {
            value: e?.value,
            brand: FindBrand?._id,
            user_id: userData?._id
        };
        dispatch(AddBlockVisitorProperties(data, usertoken, handleCancel, message, onSuccess));
    };

    const onSuccess = () => {
        let banneddata = {
            user_id: userData?._id
        }
        dispatch(getBlockedProperties(banneddata, usertoken));
    }

    const ondelete = (id) => {
        let data = {
            id: id
        }
        dispatch(deleteBannedPerperties(data, usertoken, onSucces));
    };

    const onSucces = () => {
        let banneddata = {
            user_id: userData?._id
        }
        dispatch(getBlockedProperties(banneddata, usertoken));
    };

    const handleCancel = () => {
        setIsModalOpenStatus(false);
        form.resetFields();
    };

    const validateIpAddress = (_, value) => {
        const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
        if (value && !ipRegex.test(value)) {
            return Promise.reject('Please enter a valid IP address');
        }
        return Promise.resolve();
    };
    return (
        <div>
            <Modal maskClosable={false} title="Banned Properties" open={isModalOpenStatus} onCancel={() => handleCancel()}
                footer={false}>
                <Form
                    initialValues={{
                        BannedBy: 'ip-address',
                    }}
                    form={form}
                    onFinish={onFinish}
                    layout={"vertical"}
                    autoComplete="off"
                >
                    {/* <Form.Item rules={[
                        {
                            required: true,
                        },
                    ]} style={{ marginBottom: 10 }} label="Name" name="name">
                        <Input />
                    </Form.Item> */}
                    <Form.Item
                        style={{ marginBottom: 10 }}
                        name="token"
                        rules={[{ required: true }]}
                        label="Website/Brand">
                        <Select
                            placeholder="Select Website/Brand"
                        >
                            {brandsData?.map((i, index) => (
                                <Select.Option key={index} value={i?.token}>{i?.brandname}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item rules={[
                        { required: true },
                        { validator: validateIpAddress },
                    ]}

                        style={{ marginBottom: 10 }} label="Value" name="value">
                        <Input placeholder="Enter Ip Address" />
                    </Form.Item>

                    <p style={{ textAlign: "center" }}>Set value which u want to ban the ip. eg:111.111.1.1</p>
                    <Form.Item
                        wrapperCol={{
                            span: 24,
                        }}
                    >  <Button style={{ marginRight: 10 }} key="cancel" onClick={() => handleCancel()}>
                            Cancel
                        </Button>

                        <Button disabled={isLoadingbannedProperties} type="primary" htmlType="submit">
                            {isLoadingbannedProperties ? <Spin indicator={antIcon} style={{ padding: "0px 17px 0px 17px" }} /> : "Save Changes"}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Table
                loading={isLoadinggetbannedProperties}
                columns={columnsProperties}
                dataSource={data?.map((item, index) => ({ ...item, key: index }))}
            />
        </div>
    )
}
