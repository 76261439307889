import React, { useState } from "react";
import { Button, Col, Row, Select, Switch } from "antd";

const AgentsRoles = () => {
  const [selectRole, setSelectRole] = useState("");

  const handleChange = (value) => {
    setSelectRole(value);
  };

  return (
    <div className="agent-roles-wrapper-main">
      <h1>Roles</h1>
      <div className="agent-roles-select">
        <Select
          onChange={handleChange}
          placeholder="Select Role"
          className="role_select"
          options={[
            { value: "sub-admin", label: <span>Sub-Admin</span> },
            { value: "agent", label: <span>Agent</span> },
          ]}
        />
        <Button type="primary">Search</Button>
      </div>
      <div className="permissions-wrapper">
        <h1 className="permissions">Permissions</h1>
        <Row className="permissions-row">
          <Col span={8}>
            <div className="permission-inner">
              <p>View Dashboard</p>
              <Switch
              //   checked={}
              //   onChange={}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className="permission-inner">
              <p>View Dashboard</p>
              <Switch
              //   checked={}
              //   onChange={}
              />
            </div>
          </Col>
          <Col span={8}>
            <div className="permission-inner">
              <p>View Dashboard</p>
              <Switch
              //   checked={}
              //   onChange={}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AgentsRoles;
