import React, { useState, useEffect } from 'react';
import {
    Form,
    Switch,
    Input,
    Row,
    Col,
    Button,
    message,
    Select,
    Spin
} from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { Get_All_Brands, Get_Brands_By_Id } from '../../redux/Brands/BrandsActions';
import { addGreetings, getGreetingData } from '../../redux/Widget/WidgetActions';
import * as actionTypes from "../../redux/Widget/WidgetTypes.js";

const { TextArea } = Input;

export default function Greetings() {
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
    const [showInfoForm, setShowInfoForm] = useState(false);
    const [BrandToken, setBrandToken] = useState(null);
    const userData = useSelector((state) => state.Auth.userData);
    const allDataBrand = useSelector((state) => state.Brands.data);
    const usertoken = useSelector((state) => state.Auth.token);
    const greetingData = useSelector((state) => state.WidgetReducer.greetingData);
    const isLoadingGreeting = useSelector((state) => state.WidgetReducer.isLoadingGreeting);
    const isLoadingAddGreeting = useSelector((state) => state.WidgetReducer.isLoadingAddGreeting);

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    let filtredBrands = allDataBrand?.map((i) => {
        return {
            label: i?.brandname,
            value: i?.token
        }
    });

    useEffect(() => {
        if (greetingData !== null) {
            form.setFieldsValue({
                welcomeMessage: greetingData?.welcomeMessage,
            });
            setShowWelcomeMessage(greetingData?.welcomeIsActive);
            setShowInfoForm(greetingData?.formIsActive);
        }
        else {
            form.resetFields();
            setShowWelcomeMessage(false);
            setShowInfoForm(false);
        }

    }, [greetingData]);

    useEffect(() => {
        const data = {
            companyId: userData?.companyId,
            id: userData?._id
        };
        dispatch(Get_Brands_By_Id(data, usertoken));
    }, []);

    useEffect(() => {
        const data = {
            websiteToken: BrandToken
        };
        dispatch(getGreetingData(data, usertoken));
        return () => {
            dispatch({
                type: actionTypes.GREETING_CLEAN
            });
        }
    }, [BrandToken]);

    const onSuccess = (msg) => {
        if (msg) {
            message.success(`${msg}`);
            // form.resetFields();
        }
    };

    const onFinish = (values) => {
        let data = {
            welcomeIsActive: showWelcomeMessage,
            welcomeMessage: values?.welcomeMessage === undefined ? "" : values?.welcomeMessage,
            formIsActive: showInfoForm,
            websiteToken: BrandToken
        };
        dispatch(addGreetings(data, usertoken, onSuccess))
    };

    // Handlers to toggle switches
    const handleWelcomeMessageChange = (checked) => {
        setShowWelcomeMessage(checked);
        if (checked) {
            setShowInfoForm(false);
        }
    };

    const handleInfoFormChange = (checked) => {
        setShowInfoForm(checked);
        if (checked) {
            setShowWelcomeMessage(false);
        }
    };

    const handleChangeDrop = (value) => {
        setBrandToken(value)
    };

    return (
        <Row>
            <Col span={24}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "#56777a" }}>
                    <h6 style={{ paddingLeft: 10, color: "#fff" }}>Welcome Message</h6>
                    <Select
                        placeholder="Select Webiste"
                        value={BrandToken}
                        // defaultValue="Chat"
                        style={{ width: 150, marginBottom: 15, marginRight: 20 }}
                        onChange={handleChangeDrop}
                        options={filtredBrands}
                    />
                </div>
                {BrandToken !== null &&
                    <>
                        {isLoadingGreeting || isLoadingGreeting ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80vh" }}>
                                <Spin size="large" tip="Loading..." />
                            </div>
                            :
                            <Form
                                form={form}
                                name="basic"
                                labelCol={{
                                    span: 8,
                                }}
                                wrapperCol={{
                                    span: 18,
                                }}
                                style={{
                                    maxWidth: 600,
                                }}
                                onFinish={onFinish}
                            >
                                <Form.Item
                                    // name="welcomeMessageCheck"
                                    label="Show Welcome Message"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        checked={showWelcomeMessage}
                                        onChange={handleWelcomeMessageChange}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="welcomeMessage"
                                    label="Welcome Message Here"
                                    rules={[
                                        {
                                            required: showWelcomeMessage ? true : false,
                                            message: 'Please Write Your Message!',
                                        },
                                    ]}>
                                    <TextArea disabled={!showWelcomeMessage} rows={4} maxlength={75} />
                                </Form.Item>
                                <h6>Information Form</h6>
                                <Form.Item
                                    // name="showInfoFrom"
                                    label="Show Information Form"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        defaultChecked={showInfoForm}
                                        checked={showInfoForm}
                                        onChange={handleInfoFormChange}
                                    />
                                </Form.Item>

                                <Form.Item
                                    wrapperCol={{
                                        offset: 8,
                                        span: 16,
                                    }}
                                >
                                    <Button disabled={isLoadingAddGreeting} loading={isLoadingAddGreeting} type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>}
                    </>}



            </Col>
        </Row>
    );
}
