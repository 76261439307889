import { EditOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Popconfirm, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getPlans } from '../../redux/Packages/PackagesActions';
const { Search } = Input;

const Plans = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.Auth.userData);
    const usertoken = useSelector((state) => state.Auth.token);
    const plans = useSelector((state) => state.PackagesReducer.plans);
    const planLoading = useSelector((state) => state.PackagesReducer.planLoading);

    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        dispatch(getPlans(usertoken));
    }, []);



    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRows.length > 0) {
                setToggle(true);
            } else {
                setToggle(false);
            }
        },
    };

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            render: (text, item) => (
                < Space size="middle" >
                    <a style={{ textDecoration: "underline" }} onClick={() => navigate('/packages', { state: { data: item?.action } })}>{text}</a>
                </Space >
            ),
        },
        {
            title: "Duration(Days)",
            dataIndex: "duration",
        },
        {
            title: "Action",
            key: "action",
            render: (text) => (
                < Space size="middle" >
                    <a onClick={() => navigate('/edit-plan', { state: { data: text } })}><EditOutlined /></a>
                </Space >
            ),
        },
    ];

    const data = [];
    for (let i = 0; i < plans?.length; i++) {
        data.push({
            key: i,
            serial: i + 1,
            title: plans[i]?.title,
            duration: plans[i]?.duration,
            action: plans[i]?._id
        });
    }

    return (
        <>
            <div className="topbtn">
                {userData?.role === "superadmin" && <Button type="primary" onClick={() => navigate('/create-plan')}>
                    Create Plan
                </Button>}
            </div>
            <Table
                loading={planLoading}
                pagination={false}
                // rowSelection={rowSelection}
                columns={columns}
                dataSource={data}
            />
        </>
    );
}

export default Plans;
