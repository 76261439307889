import React, { useContext, useState, useEffect } from 'react';
import { Button, Modal, Checkbox, List } from 'antd';
import { SocketContext } from '../../context/socketContext';
import { useDispatch, useSelector } from "react-redux";
import { onLeaveServed } from '../../redux/VisitorsJoined/VisitorsJoinedActions';

const TransferModal = ({ SelectedVisitorData, transferModalOpen, setTransferModalOpen }) => {
    const { Socket, FiltredUers, setTranserStatus, TranserStatus } = useContext(SocketContext);
    const userData = useSelector((state) => state.Auth.userData);
    const servedData = useSelector((state) => state.VisitorReducer.data);
    const dispatch = useDispatch();
    const [SelectedRow, setSelectedRow] = useState(null);
    const [statusCurrennt, setstatusCurrennt] = useState(null);
    const handleOk = () => {
        if (SelectedRow !== null) {
            Socket.emit("transferToAgent", {
                visitorData: SelectedVisitorData,
                agentData: SelectedRow,
                senderData: {
                    name: userData?.sudoName,
                    id: userData?._id,
                }
            }, (data) => {
                setstatusCurrennt(data);
            });
        }
    };

    useEffect(() => {
        if (!Socket) return;
        Socket.on("acceptStatus", (data) => {
            setstatusCurrennt(data);
            if (data?.status === "Accepted") {
                if (data?.visitorData?.unique_id == SelectedVisitorData?.unique_id) {
                    Socket.emit("agentLeaveRoomAfterTransfer", data);
                    setTransferModalOpen(false);
                }
                setTimeout(() => {
                    dispatch(onLeaveServed(data?.visitorData, servedData));
                }, 300);
            };
        });
    }, [Socket]);

    useEffect(() => {
        if (statusCurrennt === null) return;
        if (TranserStatus?.length > 0) {
            let isInArrr = TranserStatus?.some((i) => i?.senderID == userData?._id && i?.visitorData?.unique_id == SelectedVisitorData?.unique_id);
            if (isInArrr) {
                let NewisInArrr = TranserStatus?.findIndex((i) => i?.senderID == userData?._id && i?.visitorData?.unique_id == SelectedVisitorData?.unique_id);
                const updatedData = [...TranserStatus]
                // Delete item at the desired index
                updatedData.splice(NewisInArrr, 1);
                // Add new data at the same index
                updatedData.splice(NewisInArrr, 0, statusCurrennt);
                setTranserStatus(updatedData);
            }
            else {
                setTranserStatus((pre) => [statusCurrennt, ...pre]);
            }
        }
        else {
            setTranserStatus([statusCurrennt]);
        }
    }, [statusCurrennt]);

    const handleCancel = () => {
        setTransferModalOpen(false);
    };

    const onRowClick = (item) => {
        if (SelectedRow === null) {
            setSelectedRow(item);
        }
        else {
            setSelectedRow(null);
        }
    }
    let findStatus = TranserStatus?.find((i) => i?.visitorData?.unique_id == SelectedVisitorData?.unique_id);
    return (
        <>
            <Modal title="Select Agent To Transfer" open={transferModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancle
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk}>
                    Send
                </Button>
            ]}>
                <List
                    itemLayout="horizontal"
                    dataSource={FiltredUers}

                    renderItem={(item, index) => (
                        <>
                            {item?.status == 22 && <List.Item key={index} style={{ padding: '4px 0px' }} onClick={(() => onRowClick(item))}>
                                <div style={{ marginRight: 10 }}>
                                    <Checkbox checked={SelectedRow?.name === item?.name} />
                                </div>
                                <List.Item.Meta
                                    key={index}
                                    title={<p>{`${item?.name}-(${item?.sudoName})`}</p>
                                    }
                                />
                                {findStatus !== undefined && findStatus?.revicer === item?._id && <p> {findStatus?.status}</p>}
                            </List.Item>}

                        </>
                    )}
                />
            </Modal>
        </>
    );
}

export default TransferModal;
