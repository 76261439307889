import { Get, Post } from "../../helpers/apicalls/apicalls";
import * as actionTypes from "./TriggersTypes";

export const getTriggerConditions = (token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_LOADING });
        Get("/agents/user/trigger/get-trigger-conditions", token)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: actionTypes.GET_TRIGGERS_CONDITIONS_SUCCESS,
                        payload: res.data
                    });
                } else {
                    dispatch({ type: actionTypes.GET_TRIGGERS_CONDITIONS_FAILED });
                }
            })
            .catch((err) => {

                dispatch({ type: actionTypes.GET_TRIGGERS_CONDITIONS_FAILED });
            });
    }
};


export const getTriggerActions = (token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_LOADING });
        Get("/agents/user/trigger/get-trigger-actions", token)
            .then(function (response) {
                if (response) {
                    dispatch({
                        type: actionTypes.GET_TRIGGERS_ACTIONS_SUCCESS,
                        payload: response?.data
                    });
                } else {
                    dispatch({ type: actionTypes.GET_TRIGGERS_ACTIONS_FAILED });
                }
            })
            .catch(function (error) {

                dispatch({ type: actionTypes.GET_TRIGGERS_ACTIONS_FAILED });
            });
    };
}

export const addTrigger = (data, token, onSuccessRedirection) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_LOADING });
        Post("/agents/user/trigger/add-trigger", data, token)
            .then((res) => {
                if (res.status) {
                    dispatch({ type: actionTypes.ADD_TRIGGERS_SUCCESS });
                    onSuccessRedirection(res.message);
                } else {
                    dispatch({ type: actionTypes.ADD_TRIGGERS_FAILED })
                }
            })
            .catch(function (error) {
                dispatch({ type: actionTypes.ADD_TRIGGERS_FAILED });
            });
    }
}

export const getAllTriggers = (token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_LOADING });
        Get("/agents/user/trigger/get-all-triggers", token)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: actionTypes.GET_TRIGGERS_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.GET_TRIGGERS_FAILED })
                }
            })
            .catch(function (error) {
                dispatch({ type: actionTypes.GET_TRIGGERS_FAILED });
            });
    }
}

export const deleteTriggerById = (triggerId, token, onSuccess) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_LOADING });
        Post("/agents/user/trigger/delete-trigger-by-id", { id: triggerId }, token)
            .then((res) => {
                if (res.status) {
                    onSuccess(res.message);
                    dispatch({
                        type: actionTypes.DELETE_TRIGGER_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.DELETE_TRIGGER_FAILED });
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.DELETE_TRIGGER_FAILED });
            });
    }
}

export const getTriggerById = (triggerId, token) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_LOADING });
        Post("/agents/user/trigger/get-trigger-by-id", { id: triggerId }, token)
            .then((res) => {
                if (res.status) {
                    dispatch({
                        type: actionTypes.GET_TRIGGER_BY_ID_SUCCESS,
                        payload: res?.data
                    });
                } else {
                    dispatch({ type: actionTypes.GET_TRIGGER_BY_ID_FAILED });
                }
            })
            .catch((err) => {
                dispatch({ type: actionTypes.GET_TRIGGER_BY_ID_FAILED });
            });
    }
}

export const updateTrigger = (data, token, onSuccess) => {
    return (dispatch) => {
        dispatch({ type: actionTypes.IS_LOADING });
        Post("/agents/user/trigger/update-trigger", { data: data }, token)
            .then((res) => {
                if (res.status) {
                    dispatch({ type: actionTypes.UPDATE_TRIGGERS_SUCCESS });
                    onSuccess(res.message);
                } else {
                    dispatch({ type: actionTypes.UPDATE_TRIGGERS_FAILED })
                }
            })
            .catch(function (error) {
                dispatch({ type: actionTypes.UPDATE_TRIGGERS_FAILED });
            });
    }
}