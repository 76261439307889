import React, { useState, useEffect } from "react";

const Clock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatDateTime = (date) => {
    const year = date.getUTCFullYear();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getUTCMonth()];
    const day = String(date.getUTCDate()).padStart(2, "0");
    let hours = date.getUTCHours();
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    return (
      <>
        <div
          className="time"
          style={{ display: "flex", justifyContent: "space-between", gap: 10 }}
        >
          <p style={{ margin: "0px" }}>
            <span className="minute">
              {hours}:{minutes} {ampm}
            </span>
          </p>
        </div>
        <p
          style={{
            fontSize: "11px",
            fontWeight: "700",
            letterSpacing: "1px",
            color: "#4b4a4a",
          }}
        >
          {day} {month} {year}
        </p>
      </>
    );
  };

  return (
    <>
      <div
        className="clock"
        style={{
          display: "flex",
          justifyContent: "space-between",
          // marginRight: 20,
          lineHeight: "10px",
          alignItems: "center",
          marginTop: "15px",
          flexDirection: "column",
        }}
      >
        {formatDateTime(time)}
      </div>
    </>
  );
};

export default Clock;
