import React, { useState, useEffect } from "react";
import { Table, Badge, Tabs, Button, DatePicker, Input, Popconfirm, } from "antd";
import { useDispatch, useSelector } from "react-redux";

import TransTab from "./TransTab";
import UserTab from "./UserTab";
import { CloseOutlined, AudioOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { getBySearch, getVisitorAllHistory } from "../../redux/AllHistory/AllHistoryActions";
import moment from "moment";
import { useNavigate } from "react-router-dom";


const Currenthistory = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.Auth.userData);
  const usertoken = useSelector((state) => state.Auth.token);
  const overAllUsers = useSelector((state) => state.Users.overAllUsers);
  const visitorData = useSelector((state) => state.ALLHistoryReducer.dataH);
  const isLoadingH = useSelector((state) => state.ALLHistoryReducer.isLoadingH);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isShow, setisShow] = useState(false);
  const [colorCode, setColorCode] = useState(null);
  const [dateFilter, setdateFilter] = useState(null);

  const [dateString, setdateString] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);


  const handleTableChange = (pagination, page) => {
    setCurrentPage(pagination);
    setPageSize(page);
  };

  const [allData, setallData] = useState([]);
  const { TabPane } = Tabs;
  const [selectedData, setselectedData] = useState(null);

  const { Search } = Input;

  useEffect(() => {
    if (visitorData?.data?.length > 0) {
      setallData(visitorData?.data);
    } else {
      setallData([])
    }
  }, [visitorData]);

  const navigate = useNavigate();

  const onChange = (date, dateString) => {
    if (date !== "") {
      setdateFilter(date);
      setdateString(dateString);
    }
    else {
      setdateFilter(null);
      setdateString(null)
    }
  };

  useEffect(() => {
    let allTokens = userData?.assignBrands?.map((i) => i?.token);
    if (dateFilter != null) {
      let Data = {
        brand_token: allTokens,
        date: dateFilter,
        currentPage: currentPage,
        pageSize: pageSize

      };
      dispatch(getVisitorAllHistory(Data, usertoken));
    } else {
      let Data = {
        brand_token: allTokens,
        date: moment(new Date()),
        currentPage: currentPage,
        pageSize: pageSize
      };
      dispatch(getVisitorAllHistory(Data, usertoken));
    }
  }, [dateFilter, pageSize, currentPage]);

  const rowClassName = (record) => {
    return record.agentParticipants ? 'active-row' : '';
  };

  const columns = [
    {
      title: "Visitor",
      width: 150,
      render: (text, item) => {
        return <div onClick={() => handleRowClick(item)} className="visitorIcon">{text
          ?.name ? text?.name : text?.unique_id}</div>
      }
    },
    {
      title: "Agent",
      // dataIndex: "visitor",
      width: 150,
      render: (text) => {
        if (text?.pastServedBy?.length > 0) {
          let filtredUser = overAllUsers?.filter((i) => text?.pastServedBy?.find((p) => p?.id == i?._id));
          return <div className="visitorIcon">{filtredUser?.map((y) => { return y?.sudoName })}</div>
        } else {
          return <div className="visitorIcon">{"-"}</div>
        }
      }
    },
    {
      title: 'Time',
      // dataIndex: 'visitor',
      width: 240,
      render: (text) => (
        <div className="visitorIcon">
          {moment.utc(text?.updatedAt).format("DD-MM-YYYY")}
        </div>
      ),  
    },
    {
      title: "Messages",
      render: (text) => {
        const fetchMessageData = () => {
          return text?.messages
            .sort((x, y) => {
              return new Date(x?.time).toUTCString() < new Date(y?.time).toUTCString()
                ? 1
                : -1;
            })
            .reverse();
        };
        const textTypeArray = fetchMessageData()?.filter(item => item?.type === 'text' || item?.to);

        return <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="msgBadges">
            {fetchMessageData()?.length > 0 && (
              fetchMessageData()?.length
            )}
          </div>
          <div>
            {textTypeArray?.[textTypeArray?.length - 1]?.message !== "" ? (
              textTypeArray?.[textTypeArray?.length - 1]?.message
            ) : (
              textTypeArray?.[textTypeArray?.length - 1]?.file?.filename
            )}
          </div>

        </div>
      }
    },
  ];

  useEffect(() => {
    if (isShow) {
      setColorCode(Math.random().toString(16).substr(-6));
    }
  }, [isShow]);

  const handleRowClick = (record) => {
    setselectedData(record);
    // upDateIsRead(record?.visitor?.unique_id, record?.visitor?.isRead);
    setisShow(true);
  };

  const handleCloseSidebar = () => {
    setisShow(false);
  };
  const onSeachData = (data) => {
    let allTokens = userData?.assignBrands?.map((i) => i?.token);
    if (data !== "") {
      let newData = {
        brand_token: allTokens,
        search: data
      };
      dispatch(getBySearch(newData, usertoken));
    }
    else {
      let Data = {
        brand_token: allTokens,
        date: moment(new Date()),
        currentPage: currentPage,
        pageSize: pageSize
      };
      dispatch(getVisitorAllHistory(Data, usertoken));
    }
  }

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };
  console.log(allData,"allData")
  return (
    <div className="table-container-sidebarnew">
      <div className="historytopbtn">
        <div>
          <Search
            placeholder="Search"
            // onChange={(x) => onSeachData(x.target.value)}
            onSearch={(value) => onSeachData(value)}
            style={{
              width: 200,
              marginRight: 10
            }}
          />
          <DatePicker
            disabledDate={disabledDate}
            format="DD-MM-YYYY"
            onChange={onChange}
          />
        </div>
        {/* <Button onClick={() => navigate("/all-history")} type="primary" >
          All Visitors
        </Button> */}
      </div>
      <Badge.Ribbon text={dateFilter !== null ? dateString : "Today's Record's"} color="rgb(86, 119, 122)">
        <Table
          loading={isLoadingH}
          dataSource={allData?.map((item, index) => ({ ...item, key: index }))}
          columns={columns}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            pageSizeOptions: ['10', '20', '50'],
            total: visitorData?.totalCount,
            showTotal: (total, range) => `Total ${total} records | Showing ${range[0]}-${range[1]} records`,
            onChange: handleTableChange,

          }}
          rowClassName={rowClassName}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => handleRowClick(record),
            };
          }}
          style={{ marginTop: "20px" }}
        />
      </Badge.Ribbon>
      <div>
        <div className={`sidebarnew ${isShow ? "open" : "closed"}`}>
          <a className="crossBtn" onClick={handleCloseSidebar}>
            <CloseOutlined />
          </a>
          <div className="currentHistory">
            <Tabs defaultActiveKey="1">
              <TabPane tab="Transcript" key="1">
                <TransTab selectedData={selectedData} colorCode={colorCode} />
              </TabPane>
              <TabPane tab="User" key="2">
                <UserTab selectedData={selectedData} />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Currenthistory;