import React, { useState } from "react";
import { Layout, theme, Space, Badge, } from "antd";
import { Avatar } from "antd";
import { FormOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
// import { DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "antd";
import * as actionTypes from "../../redux/Auth/AuthTypes";
import { useNavigate, useLocation } from "react-router-dom";
import Clock from "../../components/clock";

const AdminHeaders = () => {
  const userData = useSelector((state) => state.Auth.userData);
  // const usertoken = useSelector((state) => state.Auth.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();

  const LogoutUser = () => {
    dispatch({ type: actionTypes.USER_LOGOUT });
    localStorage.removeItem("reduxPersist:root");
    sessionStorage.removeItem("reduxPersist:root");
    navigate("/");
  };


  const { Header } = Layout;

  const items = [
    {
      label: <h6 className="infoTag">Personal Info</h6>,
      key: "heading",
    },
    {
      label: <div className="infos">{userData?.sudoName}<FormOutlined /></div>,
      key: "1",
      onClick: () => {
        navigate("/personal");
      },
    },
    {
      label: <div style={{ textAlign: 'left' }}>Logout</div>,
      key: "5",
      onClick: () => {
        LogoutUser();
      },
      icon: <LogoutOutlined />,
      className: 'logoutBtn'

    }
  ];

  const {
    token: { colorBgContainer }
  } = theme.useToken();

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Header
          className="header"
          style={{
            background: colorBgContainer,
          }}
        >
          <div className="topHeader">
            <Clock />
            <div style={{ marginLeft: 10 }}></div>
            <Dropdown
              menu={{
                items,
              }}
              trigger={["click"]}
              className="Drops"
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space size={24}>
                  <Avatar icon={<UserOutlined />} />
                </Space>
              </a>
            </Dropdown>
          </div>
        </Header>
      </div>
    </>

  );
};

export default AdminHeaders;
