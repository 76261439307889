import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import logo1 from "../../assets/image/ConvoBird-logo-final-03.png";
import logo2 from "../../assets/image/bird.png"
import { useLocation, useNavigate, } from "react-router-dom";
import { AdminSideBar } from "./index";

const AdminSidebar = ({ collapsed, setCollapsed }) => {
  //hooks
  const location = useLocation();
  let navigate = useNavigate();
  // const usertoken = useSelector((state) => state.Auth.token);
  // const userData = useSelector((state) => state.Auth.userData);
  const [selectedKey, setselectedKey] = useState(null);
  const currentPath = location.pathname;
  const { Sider } = Layout;
  // const dispatch = useDispatch();
  return (
    <Sider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 1
      }}
      className="sider"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div onClick={() => navigate("/")} className={collapsed ? "minlogo" : "logo"} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {collapsed ? <img src={logo2} style={{ height: 'auto', width: 35, marginLeft: 2, marginRight: 2 }} />
          :
          <img src={logo1} style={{ height: 'auto', width: "80%", marginLeft: 2, marginRight: 2 }} />}
      </div>
      <Menu
        className="menu"
        theme="dark"
        selectedKeys={selectedKey !== null ? [selectedKey] : [currentPath]}
        defaultOpenKeys={['14']}
        mode="inline"
        items={AdminSideBar(navigate, setselectedKey)}
      />
    </Sider>
  );
};

export default AdminSidebar;
